import { AccountMeta, Connection, Keypair, PublicKey, sendAndConfirmTransaction, TransactionInstruction } from '@solana/web3.js';

export * from '@solana/wallet-adapter-wallets';
export * from '@solana/web3.js'
export * from './sdk';
export * from 'buffer';
export { RootAccount } from './types';
export { TOKEN_2022_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import bs58 from 'bs58';
import { NetworkRecord, NetworkRecordLength, NetworkStringLength, NicknameStringLength, readPk, RootAccount, RootAccountOffsets, RootAccountTag, TradeArgs } from './types';

export function getRootAccount() {
    return new RootAccount();
};

export function receiveBigIntFromDart(bigIntAsString) {
    let jsBigInt = BigInt(bigIntAsString);

    console.log(jsBigInt)
    return jsBigInt;

}


export function createTransactionInstruction(
    keys: AccountMeta[],
    programId: PublicKey,
    data: Buffer,
) {
    return new TransactionInstruction({
        keys: keys,
        programId: programId,
        data: data,
    });
}

export function createAccountMeta(
    pubkey: PublicKey,
    isSigner: boolean,
    isWritable: boolean,
): AccountMeta {
    return {
        pubkey,
        isSigner,
        isWritable,
    };
}

export function stringToKeypair(privateKeyString: string): Keypair {
    let privateKeyArray: Uint8Array;

    // Detect if it's a JSON array string (starts with '[')
    if (privateKeyString.startsWith('[')) {
        // Parse JSON string to an array
        privateKeyArray = Uint8Array.from(JSON.parse(privateKeyString));
    }
    // Detect if it's base58-encoded (common in Solana)
    else if (/^[1-9A-HJ-NP-Za-km-z]+$/.test(privateKeyString)) {
        privateKeyArray = bs58.decode(privateKeyString);
    }
    // Assume it's base64-encoded
    else {
        privateKeyArray = Uint8Array.from(atob(privateKeyString), c => c.charCodeAt(0));
    }

    // Generate and return the Keypair
    return Keypair.fromSecretKey(privateKeyArray);
}



export function fixBuff(args: TradeArgs): Buffer {
    var buf = Buffer.alloc(80);
    buf.writeUint8(4, 0);
    buf.writeUint32LE(args.networkId, 4);
    buf.writeBigInt64LE(BigInt(args.amount * 1000000), 32);
    if (args.nickname != undefined) {
        buf.write(args.nickname, 48, Math.min(NicknameStringLength, args.nickname.length), 'utf-8');
    }
    if (args.limit != undefined && args.limit > 0) {
        buf.writeBigInt64LE(BigInt(args.limit * 1000000), 40);
    }
    buf.write(args.address.toLowerCase(), 8, Math.min(NetworkStringLength, args.address.length), 'utf-8');

    return buf;
}